#department-accordion-item {
    border: unset !important;
    #department-accordion-header {
        .accordion-button.collapsed {
            background: #EEF6FF !important;
            padding: 6px 12px !important
        }
        .accordion-button:not(.collapsed) {
            background: #EEF6FF !important;
            padding: 6px 12px !important
        }
    }
}
#department-accordion-body {
        padding: 6px 8px 0 4px !important;
        box-shadow: var(--#{$prefix}box-shadow-sm);
}